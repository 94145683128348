/* eslint-disable line-comment-position */
import {
  IStyle,
  mergeStyleSets
} from '@fluentui/react';

// Custom implementation for getScrollBarWidth
export const getScrollBarWidth = (): number => {
  const div = document.createElement('div');
  div.style.width = '100px';
  div.style.height = '100px';
  div.style.overflow = 'scroll';
  div.style.position = 'absolute';
  div.style.top = '-9999px';
  document.body.appendChild(div);
  const scrollBarWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  return scrollBarWidth;
};

// Custom constant for navCollapsedWidth
export const navCollapsedWidth = 48;

const mainStyles = {
  position: 'fixed',
  top: 94,
  bottom: 0,
  right: 0,
  overflowY: 'scroll',
  overflowX: 'scroll'
};

const breadcrumbStyles = {
  position: 'fixed',
  top: 40,
  bottom: 0,
  right: 0,
  marginLeft: 0
};

const breadcrumDividerStyles = {
  width: '100%', // Make it span the full width of the parent container
  height: '1px', // Set a fixed height for the divider line
  margin: 0, // Remove default margin
  border: 'none', // Remove default border
  backgroundColor: '#ccc', // Set a solid color for the divider
  boxSizing: 'border-box' // Include padding/border in the total width calculation
};

let scrollBarWidth = 0;
// let currentZoomLevel = 0;

const ADDITIONAL_SPACING_FOR_UPGRADE_BANNER = {
  COLLAPSED: 17,
  EXPANDED: 14
};

const calculateScrollBarWidth = () => {
  scrollBarWidth = getScrollBarWidth();
  return scrollBarWidth;
};

export const AlertPipelineStyles = mergeStyleSets({
  mainCollapsed: {
    ...mainStyles,
    left: navCollapsedWidth + calculateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.COLLAPSED
  } as IStyle,
  mainExpand: {
    ...mainStyles,
    left: 250 + calculateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.EXPANDED
  } as IStyle,
  breadcrumbCollapsed: {
    ...breadcrumbStyles,
    left: navCollapsedWidth + calculateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.COLLAPSED
  } as IStyle,
  breadcrumbExpand: {
    ...breadcrumbStyles,
    left: 221 + calculateScrollBarWidth() + ADDITIONAL_SPACING_FOR_UPGRADE_BANNER.EXPANDED
  } as IStyle,
  breadcrumbDivider: {
    ...breadcrumDividerStyles
  } as IStyle,
  rootDiv: {
    paddingRight: '30px',
    paddingLeft: '10px'
  } as IStyle,
  pageHeader: {
    marginBottom: '10px'
  } as IStyle,
  spacerDiv: {
    height: '34px'
  } as IStyle,
  dividerLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'black',
    marginBottom: '20px'
  } as IStyle,
  rowGap: {
    height: '30px'
  } as IStyle
});
