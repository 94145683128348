/* eslint-disable max-len */
// Static CSS
import './custom.css';

// React Imports
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { IAccountInfo } from 'react-aad-msal';

// Fluent UI Imports
import { ThemeProvider, createTheme } from '@fluentui/react';

// CSEO Imports
// import { CoherenceCustomizations, CoherenceTheme } from '@coherence-design-system/styles';

// ALP Pages
import { AlertOnboardCrudListPage } from './pages/AlertOnboardCrudList.Page';
import { AlertOnboardDetailsPage } from './pages/AlertOnboardDetails.Page';
import { AlertPipelineCrudListPage } from './pages/AlertPipelineCrudList.Page';
import { RuleDetailsPage } from './pages/RuleDetails.Page';
import { SignalSourcesPage } from './pages/SignalSources.Page';

// ALP Components

import { AlertPipelineBreadcrumb } from './components/AlertPipelineBreadcrumb.Component';
import { AlertPipelineHeader } from './components/AlertPipelineHeader.Component';
import { AlertPipelineNav } from './components/AlertPipelineNav.Component';

// ALP Services
import { AuthService } from './services/Auth.Service';

// ALP Types
import { AlertPipelineCrudObjectType } from './common/Types';

// ALP Styles
import { AlertPipelineStyles } from './styles/custom.styles';

interface Props {
}

interface State {
  loggedIn: boolean,
  accountInfo?: IAccountInfo,
  accessToken?: string,
  isNavCollapsed: boolean,
  hideBreadcrumb: boolean,
}

export default class App extends Component<Props, State> {
  static displayName = 'SmartPipeline UI';

  state: State = {
    loggedIn: false,
    isNavCollapsed: false,
    hideBreadcrumb: false
  };

  constructor (props: Props) {
    super(props);
    try {
      const accountInfo = AuthService.getAccountInfo();
      this.state = {
        loggedIn: !!accountInfo,
        accountInfo: accountInfo as IAccountInfo,
        isNavCollapsed: false,
        hideBreadcrumb: false
      };

      AuthService.getAccessToken()
        .then((res) => {
          this.setState({ accessToken: res.accessToken });
        })
        .catch((err) => {
          console.warn(err);
          this.state = {
            loggedIn: false,
            isNavCollapsed: false,
            hideBreadcrumb: false
          };
        });
    } catch (err) {
      this.state = {
        loggedIn: false,
        isNavCollapsed: false,
        hideBreadcrumb: false
      };
    }
  }

  private onNavCollapsed = (isCollapsed: boolean) => {
    this.setState({ isNavCollapsed: isCollapsed });
  };

  private toggleBreadCrumb = (isToggled: boolean) => {
    this.setState({ hideBreadcrumb: isToggled });
  };

  // Create a custom theme
  customTheme = createTheme({
    palette: {
      themePrimary: '#0078d4',
      themeLighterAlt: '#f3f9fd',
      themeLighter: '#d0e7f8',
      themeLight: '#a9d3f2',
      themeTertiary: '#5ca9e5',
      themeSecondary: '#1a86d9',
      themeDarkAlt: '#006cbe',
      themeDark: '#005ba1',
      themeDarker: '#004377',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff'
    }
  });

  headerTheme = createTheme({
    semanticColors: {
      bodyBackground: '#0078d4',
      bodyText: '#323130',
      bodyDivider: '#ffffff',
      bodyFrameBackground: '#ffffff',
      bodyFrameDivider: '#ffffff',
      buttonBackgroundCheckedHovered: '#0078d4',
      bodyTextChecked: '#323130',
      bodySubtext: '#323130'
    }
  });

  render () {
    return (
      <ThemeProvider>
        <ThemeProvider theme={this.headerTheme}>
        <AlertPipelineHeader { ...this.state }></AlertPipelineHeader>
        </ThemeProvider>
        <AlertPipelineNav { ...this.state } onNavCollapsed={ this.onNavCollapsed } />
        {<AlertPipelineBreadcrumb isNavCollapsed={this.state.isNavCollapsed}/>}
        <main
          id='main'
          key='main'
          tabIndex={ -1 }
          className={ this.state.isNavCollapsed ? AlertPipelineStyles.mainCollapsed : AlertPipelineStyles.mainExpand }>
          {
            this.state.loggedIn
              ? <Routes>
                <Route path="/" element={ <SignalSourcesPage { ...this.state }/> } />
                <Route path="/signalsources" element={ <SignalSourcesPage { ...this.state }/> } />

                <Route path="/security"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.SecurityPool }
                    baseUrl='/security/' /> } />
                <Route path="/security/:signalSourceId"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.SecurityPool }
                    baseUrl='/security/' /> } />

                <Route path="/rules"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Rule }
                    baseUrl='/rules/' /> } />
                <Route path="/rules/:signalSourceId"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Rule }
                    baseUrl='/rules/' /> } />
                <Route path="/rules/:signalSourceId/:id"
                  element={ <RuleDetailsPage { ...this.state } isEditable={ false } /> } />
                <Route path="/rules/:signalSourceId/:id/edit"
                  element={ <RuleDetailsPage { ...this.state } isEditable={ true } /> } />
                <Route path="/rules/:signalSourceId/create"
                  element={ <RuleDetailsPage { ...this.state } isEditable={ true } /> } />

                <Route path="/actions"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Action }
                    baseUrl='/actions/' /> } />
                <Route path="/actions/:signalSourceId"
                  element={ <AlertPipelineCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.Action }
                    baseUrl='/actions/' /> } />

                <Route path="/alertonboard"
                  element={ <AlertOnboardCrudListPage
                    { ...this.state }
                    type={ AlertPipelineCrudObjectType.AlertOnboard }
                    baseUrl='/alertonboard/'/> } />
                <Route path="/alertonboard/:id"
                  element={ <AlertOnboardDetailsPage { ...this.state } isEditable={ false } /> } />
                <Route path="/alertonboard/:id/edit"
                  element={ <AlertOnboardDetailsPage { ...this.state } isEditable={ true } /> } />
                <Route path="/alertonboard/create"
                  element={ <AlertOnboardDetailsPage { ...this.state } isEditable={ true } /> } />

              </Routes>
              : <div data-testid='unauthorized'>Unauthorized</div>
          }

        </main>
      </ThemeProvider>
    );
  }
}
