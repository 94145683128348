/* eslint-disable line-comment-position */
import '../custom.css';

import {
  ActionButton,
  FontWeights,
  IIconProps,
  IStyle,
  mergeStyleSets,
  mergeStyles,
  FontSizes,
  getTheme,
  CommandBar,
  ICommandBarItemProps,
  Persona,
  PersonaSize,
  Panel,
  PanelType,
  initializeIcons
} from '@fluentui/react';

import { AuthService } from '../services/Auth.Service';
import { GraphService } from '../services/Graph.Service';
import { IAccountInfo } from 'react-aad-msal';

import React, { useEffect, useState } from 'react';
interface Props {
  accountInfo?: IAccountInfo,
  accessToken?: string
}
initializeIcons();
export const AlertPipelineHeader: React.FC<Props> = (props) => {

  const [userImage, setUserImage] = useState<string>();
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.accountInfo) {
      GraphService.getAccountPhoto(
        props.accountInfo.account.userName,
        props.accountInfo.account.accountIdentifier)
        .then((response) => {
          if (response) {
            setUserImage(response);
          }
        })
        .catch((error) => {
          if (error.toString().indexOf('has not consented') > 0) {
            const authUrl = 'https://login.microsoftonline.com/microsoft.onmicrosoft.com/oauth2/v2.0/authorize?' +
              `client_id=${process.env.REACT_APP_PORTAL_AUTH_CLIENT_ID}&` +
              'response_type=code&' +
              `redirect_uri=${window.location.protocol}//${window.location.host}&` +
              'response_mode=query&' +
              'scope=https://graph.microsoft.com/User.Read ' +
              'https://graph.microsoft.com/User.ReadBasic.All';

            window.open(authUrl);
          }
        });
    }
  }, [props.accountInfo]);

  const featureRequestIcon: IIconProps = { iconName: 'ArrowUpRight' };
  const contactIcon: IIconProps = { iconName: 'Contact' };
  const infoIcon: IIconProps = { iconName: 'Info' };
  const swaggerIcon: IIconProps = { iconName: 'AllApps' };
  const theme = getTheme();

  const settingsStyles = mergeStyleSets({
    root: {
      marginLeft: '-10px',
      marginRight: '-10px'
    } as IStyle
  });

  const helpSampleLinkStyles = mergeStyleSets({
    helpSampleLink: {
      margin: '8px 0',
      display: 'block',
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold
    } as IStyle,
    helpSampleList: {
      listStyle: 'none',
      paddingLeft: '0'
    } as IStyle
  });

  const profilePanelSampleStyles = {
    root: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    rootHovered: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    rootFocused: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    rootPressed: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    icon: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    iconHovered: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    iconFocused: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    iconPressed: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ]
  };

  const profilePanelSample: JSX.Element = (
    <div className={ settingsStyles.root }>
      <ul className={ helpSampleLinkStyles.helpSampleList }>
        <li>
          <ActionButton
            role='button'
            iconProps={ featureRequestIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.open('https://aka.ms/wpeintake', '_blank'); } }>
            Request a Feature
          </ActionButton>
        </li>
        <li>
          <ActionButton
            role='button'
            iconProps={ contactIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.location.assign('mailto:intelops@microsoft.com'); } }>
            Contact
          </ActionButton>
        </li>
        <li>
          <ActionButton
            role='button'
            iconProps={ infoIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.open('https://aka.ms/wpewiki', '_blank'); } }>
            Wiki
          </ActionButton>
        </li>
        <li>
          <ActionButton
            role='button'
            iconProps={ swaggerIcon }
            styles={ profilePanelSampleStyles }
            onClick={ () => { window.open((process.env.REACT_APP_PORTAL_API_ENDPOINT as string) + '/swagger', '_blank'); } }>
            Swagger
          </ActionButton>
              </li>
              <li>
                  <ActionButton
                      role='button'
                      iconProps={{ iconName: 'SignOut' }}
                      onClick={() => AuthService.logout()}
                      styles={profilePanelSampleStyles}
                  >
                      Log Out
                  </ActionButton>
              </li>
      </ul>
    </div>
  );

  const items: ICommandBarItemProps[] = [
    {
      key: 'Alert Pipeline',
      text: 'Home',
      ariaLabel: 'Alert Pipeline',
      buttonStyles: {
        root: {
          backgroundColor: '#0078D4',
          color: '#fff'
        }
      },
      iconProps: { iconName: 'Alert Pipeline' }
    }
  ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: 'Profile',
      text: 'Profile',
      ariaLabel: 'Profile',
      iconOnly: true,
      onRender: (item, dismissMenu) => (
          <div
              onClick={() => setIsPanelOpen(true)}
              role="button"
              style={{
                display: 'flex',
                alignItems: 'center', // Vertically center the item
                justifyContent: 'center', // Horizontally align if needed
                padding: '0 8px', // Add horizontal padding
                height: '100%' // Ensure it takes the full height of the command bar
              }}
          >
              <Persona
                  aria-label='Profile Button'
                  imageUrl={userImage}
                  size={PersonaSize.size32}
                  hidePersonaDetails
              />
          </div>
      )
    }
  ];

  const panelSettings = {
    titleText: 'Profile Details',
    fullName: props.accountInfo?.account.name,
    emailAddress: props.accountInfo?.account.userName,
    imageUrl: userImage,
    body: profilePanelSample,
    viewAccountLink: 'https://myaccount.microsoft.com/',
    logOutLink: '#'
  };

  const panelStyles = {
    root: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    commands: {
      backgroundColor: 'white'
    },
    main: {
      backgroundColor: 'white'
    },
    icon: [
      mergeStyles(FontSizes.size14),
      {
        fontWeight: FontWeights.semibold,
        color: theme.palette.black
      }
    ],
    closeButton: {
      display: 'block'
    }
  };

  return (
    <>
    <CommandBar
        items={items}
        aria-label='Alert Pipeline Header'
        farItems={farItems} />
      <Panel
              isOpen={isPanelOpen}
              onDismiss={() => setIsPanelOpen(false)}
              type={PanelType.smallFixedFar}
              headerText={panelSettings.titleText}
              closeButtonAriaLabel="Close"
              styles={panelStyles}
      >
      <br/>
        <Persona
          imageUrl={panelSettings.imageUrl}
          size={PersonaSize.size72}
          text={panelSettings.fullName}
          secondaryText={panelSettings.emailAddress}
              />
        <hr/>
        {panelSettings.body}
      </Panel>
    </>
  );
};
