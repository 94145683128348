// #region Imports

// Fluent UI Imports
import { IIconProps, mergeStyleSets, DetailsList, SelectionMode } from '@fluentui/react';
import { ActionButton } from '@fluentui/react/lib/Button';

// CSEO Imports
// import { CoherenceDataGrid } from '@coherence-design-system/controls';

// React Imports
import React from 'react';

// ALP Component Imports
import { AlertPipelineError } from '../components/AlertPipelineError.Component';
import { ListLoading } from '../components/ListLoading.Component';

// ALP Type Imports
import { AlertPipelineCrudObjectType, IAlertOnboard, IAlertPipelineCrudItem } from '../common/Types';
import { AlertPipelineCrudListColumns } from '../common/Helpers';

import ErrorImage from '../images/emptysearch.svg';
import { IAccountInfo } from 'react-aad-msal';

// #endregion Imports

interface Props<T> {
  items: T[],
  type: AlertPipelineCrudObjectType | null,
  isEmpty: boolean,
  accountInfo?: IAccountInfo,
  onCreateItem?: () => void,
  onDeleteItem?: (item: T) => void,
  onEditItem?: (item: T) => void,
  onViewItem?: (item: T) => void,
  // onApproveItem?: (item: IAlertOnboard) => void,
  // onRejectItem?: (item: IAlertOnboard) => void
}

export const AlertOnboardCrudListComponent =
  function AlertOnboardCrudListComponent<T extends IAlertPipelineCrudItem> (props: Props<T> & { children?: React.ReactNode }) {

    const addIcon: IIconProps = { iconName: 'Add' };
    const styles = mergeStyleSets({
      spacer: {
        height: '200px'
      }
    });

    let columns: any;
    switch (props.type) {
      case AlertPipelineCrudObjectType.Action:
        columns = AlertPipelineCrudListColumns.getActionColumns(
          // props.onViewItem as unknown as (item: IAction) => void,
          // props.onEditItem as unknown as (item: IAction) => void,
          // props.onDeleteItem as unknown as (item: IAction) => void);
          undefined,
          undefined,
          undefined);
        break;
      case AlertPipelineCrudObjectType.Rule:
        columns = AlertPipelineCrudListColumns.getRuleColumns(props.accountInfo,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined);
        break;
      case AlertPipelineCrudObjectType.SecurityPool:
        columns = AlertPipelineCrudListColumns.getSecurityPoolColumns(
          // props.onViewItem as unknown as (item: ISecurityPool) => void,
          // props.onEditItem as unknown as (item: ISecurityPool) => void);
          undefined,
          undefined);
        break;
      case AlertPipelineCrudObjectType.AlertOnboard:
        columns = AlertPipelineCrudListColumns.getAlertOnboardColumns(props.accountInfo,
          props.onViewItem as unknown as (item: IAlertOnboard) => void,
          props.onEditItem as unknown as (item: IAlertOnboard) => void,
          props.onDeleteItem as unknown as (item: IAlertOnboard) => void);

        break;
      default:
        columns = [];
    }

    const mainContent = (
      <div>
      <div>
        {
          props.type === AlertPipelineCrudObjectType.AlertOnboard
            ? <ActionButton iconProps={ addIcon } allowDisabledFocus onClick={ props.onCreateItem }>
              Create New
            </ActionButton>
            : <></>
        }
        <div className={ styles.spacer }>
          <DetailsList
            columns={ columns }
            items={ props.items }
            setKey="set"
            selectionMode={SelectionMode.none}
            isHeaderVisible={true}
            />
        </div>
      </div>
    </div>
    );

    const loadingContent = (
      <ListLoading
        groupCount={ 1 }
        groupSpacing={ 36 }
        rowCount={ 10 }
        rowSpacing={ 22 }
        shimmerSize={ 18 } />
    );

    const emptyContent = (
      <AlertPipelineError
        heading='Nothing to see here'
        content={ 'We could not find any ' + props.type?.toString().toLowerCase() + ' for you.' }
        buttonText={ '+ Create New ' + props.type?.toString() }
        imageUrl={ ErrorImage }
        buttonOnClick={ props.onCreateItem }
      />
    );

    return (
      <div>
        {
          props.isEmpty
            ? emptyContent
            : props.items === undefined || props.items === null || props.items?.length === 0
              ? loadingContent
              : mainContent
        }
      </div>
    );
  };
