// import {CoherenceNav} from '@coherence-design-system/controls';
import { Nav, initializeIcons } from '@fluentui/react';
import React from 'react';
// import { useNavigate } from 'react-router-dom';

initializeIcons();
interface Props {
  accessToken?: string,
  onNavCollapsed: (isCollapsed: boolean) => void;
  onNavItemClicked?: () => void;
}

export const AlertPipelineNav: React.FC<Props> = (props) => {
  // const navigate = useNavigate();

  return (
        <div style={{ width: 250 }}> {/* Set the width of the Nav */}
    <Nav
      ariaLabel="Navigation Panel"
      groups={ [
        {
          links: [
            {
              name: 'Signal Sources',
              icon: 'CubeShape',
              key: 'signalSourcesLink',
              ariaLabel: 'signalSources',
              forceAnchor: true,
              'data-id': 'signalSourcesNavLinkDataId',
              url: '/signalsources',
              isSelected:
                window.location.href.indexOf('/signalsources') > 0 ||
                (window.location.href === window.location.protocol + '//' + window.location.host + '/')
            },
            {
              name: 'Security',
              icon: 'SecurityGroup',
              key: 'securityLink',
              ariaLabel: 'security',
              forceAnchor: true,
              'data-id': 'securityNavLinkDataId',
              url: '/security',
              isSelected: window.location.href.indexOf('/security') > 0
            },
            {
              name: 'Rules',
              icon: 'Code',
              key: 'rulesLink',
              ariaLabel: 'rules',
              forceAnchor: true,
              'data-id': 'rulesNavLinkDataId',
              url: '/rules',
              isSelected: window.location.href.indexOf('/rules') > 0
            },
            {
              name: 'Actions',
              icon: 'SetAction',
              key: 'actionsLink',
              ariaLabel: 'actions',
              forceAnchor: true,
              'data-id': 'actionsNavLinkDataId',
              url: '/actions',
              isSelected: window.location.href.indexOf('/actions') > 0
            },
            {
              name: 'Alert Onboard',
              icon: 'AddTo',
              key: 'alertonboardLink',
              ariaLabel: 'alertonboard',
              forceAnchor: true,
              'data-id': 'alertonboardNavLinkDataId',
              url: '/alertonboard',
              isSelected: window.location.href.indexOf('/alertonboard') > 0
            }
          ]
        }
      ]}
    styles={{
      root: {
        height: '100vh',
        boxShadow: '0 0 5px rgba(0,0,0,0.2)',
        backgroundColor: '#f3f2f1'
      }
    }}
            />
        </div>
  );
};
