// #region Imports

// Fluent UI Imports
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { FontWeights, getTheme, IIconProps, IStackTokens, mergeStyleSets, Modal, Stack } from '@fluentui/react';

// React Imports
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// ALP Component Imports
import { AlertOnboardCrudListComponent } from '../components/AlertOnboardCrudList.Component';
import { ListLoading } from '../components/ListLoading.Component';

// ALP Service Imports
import { AlertOnboardService } from '../services/AlertOnboard.Service';

// ALP Type Imports
import { AlertPipelineCrudObjectType, IAlertPipelineCrudItem } from '../common/Types';

import { IAccountInfo } from 'react-aad-msal';

// #endregion Imports

interface Props {
  type: AlertPipelineCrudObjectType,
  baseUrl: string,
  accessToken?: string,
  accountInfo?: IAccountInfo,
}

export const AlertOnboardCrudListPage =
  function AlertOnboardCrudListPage<T extends IAlertPipelineCrudItem> (props: Props) {

    // #region Params

    const [searchParams] = useSearchParams();

    // #endregion Params

    // #region States

    const [items, setItems] = useState<T[]>([]);
    // const [alertOnboard, setAlertOnboard] = useState<IAlertOnboard[]>([]);
    // const [selectedAlertOnboard, setSelectedAlertOnboard] = useState('');
    const [selectedItem, setSelectedItem] = useState<T>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // const [alertOnboardLoading, setAlertOnboardLoading] = useState<boolean>(true);
    const [isTabEmpty, setIsTabEmpty] = useState<boolean>(false);
    // const [alertOnboardEmpty, setAlertOnboardEmpty] = useState<boolean>(false);
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [forceRefresh, setForceRefresh] = useState<boolean>(searchParams.get('forcerefresh')?.toLowerCase() === 'true');

    // #endregion States

    // #region Consts

    const navigate = useNavigate();
    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const modalVerticalStackTokens: IStackTokens = { childrenGap: 24 };
    const modalHorizontalStackTokens: IStackTokens = { childrenGap: 18 };
    const theme = getTheme();
    const hideDeleteConfirmationModal = (): void => {
      setDeleteConfirmationModalOpen(false);
    };
    const contentStyles = mergeStyleSets({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch'
      },
      header: [
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px'
        }
      ],
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 }
        }
      }
    });

    const iconButtonStyles = {
      root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
      },
      rootHovered: {
        color: theme.palette.neutralDark
      }
    };

    // #endregion Consts

    // #region Event Handlers

    const createNewClicked = (): void => {
      navigate(props.baseUrl + 'create');
    };

    const deleteItemClicked = (item: any): void => {
      setDeleteConfirmationModalOpen(true);
      setSelectedItem(item.data);
    };

    const editItemClicked = (item: any): void => {
      setSelectedItem(item.data);
      navigate(props.baseUrl + item.data.scenarioName + '/edit');
    };

    const viewItemClicked = (item: any): void => {
      setSelectedItem(item.data);
      navigate(props.baseUrl + item.data.scenarioName);
    };

    const deleteItemConfirmationClicked = (): void => {
      setIsDeleting(true);
    };

    // #endregion Event Handlers

    useEffect(() => {

      /**
       * Fetches data from ALP APIs
       */
      async function fetchFromService () {
        if (props.accessToken) {
          const allScenarios = await AlertOnboardService.getAll(props.accessToken, forceRefresh);
          if (!allScenarios || allScenarios.length === 0) {
            setIsTabEmpty(true);
          } else {
            setItems(allScenarios as unknown as T[]);
            setIsTabEmpty(allScenarios == null || allScenarios.length === 0);
          }

          setForceRefresh(false);
          setIsLoading(false);
        }
      }
      fetchFromService();
    }, [props.accessToken, props.type, forceRefresh]);

    const loadingContent = (
      <ListLoading
        groupCount={ 1 }
        groupSpacing={ 36 }
        rowCount={ 10 }
        rowSpacing={ 22 }
        shimmerSize={ 18 } />
    );

    const mainContent = (
      <div>
        <Modal
          titleAriaId='deleteConfirmationId'
          isOpen={ deleteConfirmationModalOpen }
          onDismiss={ hideDeleteConfirmationModal }
          containerClassName={ contentStyles.container }
        >
          <div className={ contentStyles.header }>
            <span id='deleteConfirmationId'>Delete</span>
            <IconButton
              styles={ iconButtonStyles }
              iconProps={ cancelIcon }
              ariaLabel="Close Delete Confirmation"
              onClick={ hideDeleteConfirmationModal }
            />
          </div>
          <div className={ contentStyles.body }>
            <Stack tokens={ modalVerticalStackTokens }>
              <Stack.Item>
                <p>
                  Are you sure you want to delete &apos;{ selectedItem?.name }&apos;?
                </p>
              </Stack.Item>
              <Stack.Item>
                <Stack horizontal tokens={ modalHorizontalStackTokens }>
                  <DefaultButton text='Cancel' onClick={ hideDeleteConfirmationModal } allowDisabledFocus />
                  <PrimaryButton
                    text={ isDeleting ? 'Deleting' : 'Delete' }
                    onClick={ deleteItemConfirmationClicked }
                    allowDisabledFocus
                    disabled={ isDeleting } />
                </Stack>
              </Stack.Item>
            </Stack>
          </div>
          </Modal>
            <AlertOnboardCrudListComponent
                type={props.type}
                isEmpty={isTabEmpty}
                items={items}
                accountInfo={props.accountInfo}
                onCreateItem={createNewClicked}
                onViewItem={viewItemClicked as unknown as (item: T) => void}
                onEditItem={editItemClicked as unknown as (item: T) => void}
                onDeleteItem={deleteItemClicked as unknown as (item: T) => void}>
            </AlertOnboardCrudListComponent>
      </div>
    );

    return (
      <div>
        {
         isLoading
           ? loadingContent
           : mainContent
        }
      </div>
    );
  };
