import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AlertPipelineError } from '../components/AlertPipelineError.Component';
import { SignalSourcesList } from '../components/SignalSourcesList.Component';

import { ISignalSource } from '../common/Types';

import { SignalSourcesService } from '../services/SignalSources.Service';

import ErrorImage from '../images/emptysearch.svg';

interface Props {
  accessToken?: string,
}

export const SignalSourcesPage: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [resultsEmpty, setResultsEmpty] = useState<boolean>(false);
  const [signalSources, setSignalSources] = useState(() => {
    return [] as ISignalSource[];
  });

  const [forceRefresh, setForceRefresh] = useState<boolean>(searchParams.get('forcerefresh')?.toLowerCase() === 'true');

  const viewSecurityClicked = (item: any): void => {
    navigate('/security/' + item.data.shortName);
  };

  const viewRulesClicked = (item: any): void => {
    navigate('/rules/' + item.data.shortName);
  };

  const viewActionsClicked = (item: any): void => {
    navigate('/actions/' + item.data.shortName);
  };

  const viewAlertOnboardClicked = (item: any): void => {
    navigate('/alertonboard/' + item.data.shortName);
  };

  useEffect(() => {

    /**
     * Fetches collection of all signal sources from ALP APIs
     */
    async function fetchFromService () {
      if (props.accessToken) {
        const updatedSignalSources = await SignalSourcesService.getAll(props.accessToken, forceRefresh);
        setSignalSources(updatedSignalSources);
        if (!updatedSignalSources || updatedSignalSources.length === 0) {
          setResultsEmpty(true);
        }

        setForceRefresh(false);
      }
    }
    fetchFromService();

  }, [props.accessToken, forceRefresh]);

  return (
    <div>
      {
        !resultsEmpty
          ? <SignalSourcesList
            data-testid="signalsourceslist"
            items={ signalSources }
            onViewSecurity={ viewSecurityClicked }
            onViewRules={ viewRulesClicked }
            onViewActions={ viewActionsClicked }
            onViewAlertOnboard={ viewAlertOnboardClicked }>
          </SignalSourcesList>
          : <AlertPipelineError
            data-testid="signalsourcesempty"
            heading='Nothing to see here'
            content='We could not find any signal sources for you.'
            imageUrl={ ErrorImage }
          />
      }
    </div>
  );
};
