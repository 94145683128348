import { mergeStyleSets, DetailsList } from '@fluentui/react';

import React from 'react';

// import { CoherenceDataGrid } from '@coherence-design-system/controls';

import { ListLoading } from '../components/ListLoading.Component';

import { ISignalSource } from '../common/Types';

import { AlertPipelineCrudListColumns } from '../common/Helpers';

interface Props {
  items: ISignalSource[],
  onEditItem?: (item: ISignalSource) => void,
  onViewItem?: (item: ISignalSource) => void,
  onViewSecurity?: (item: ISignalSource) => void,
  onViewRules?: (item: ISignalSource) => void,
  onViewActions?: (item: ISignalSource) => void,
  onViewAlertOnboard?: (item: ISignalSource) => void
}

const styles = mergeStyleSets({
  spacer: {
    height: '200px'
  }
});

export const SignalSourcesList: React.FC<Props> = (props) => {

  const columns = AlertPipelineCrudListColumns.getSignalSourceColumns(
    // props.onViewItem as unknown as (item: ISignalSource) => void,
    // props.onEditItem as unknown as (item: ISignalSource) => void);
    undefined,
    undefined,
    props.onViewSecurity,
    props.onViewRules,
    props.onViewActions,
    props.onViewAlertOnboard);

  return (
    <div className={ styles.spacer }>
      {
        (!props.items || props.items.length === 0)
          ? <ListLoading
            groupCount={ 1 }
            groupSpacing={ 36 }
            rowCount={ 10 }
            rowSpacing={ 22 }
            shimmerSize={ 18 } />
          : <DetailsList
            columns={ columns }
            items={ props.items }
            selectionMode={ 0 }
            />
      }
    </div>
  );
};
